<template>
	<div>
		<b-card title="" header-tag="header">
			<template v-slot:header>
				<div class="card-title m-0">
					<!---->
					<h3 class="card-label m-0">{{ title }}</h3>
				</div>

			</template>

			<form class="form" novalidate="novalidate" id="product_basic">
				<div class="form-group row">
					<label class="col-xl-3 col-lg-3 col-form-label text-left">Image</label>
					<div class="col-lg-9 col-xl-9">
						<div class="image-input image-input-outline" id="kt_user_add_avatar">
							<div class="image-input-wrapper" :style="{ backgroundImage: `url(${previewImage!=null?previewImage:form.image!='' && form.image!=null?imgPath+form.image:''})` }" ></div>
							<label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
								<i class="fa fa-pen icon-sm text-muted"></i>
								<input type="file" ref="file" @change="selectImage"  name="profile_avatar" accept=".png, .jpg, .jpeg">
								<input type="hidden" name="profile_avatar_remove">
							</label>
							<span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="" data-original-title="Cancel avatar" >
								<i class="ki ki-bold-close icon-xs text-muted"></i>
							</span>
						</div>
					</div>
				</div>
				<b-form-group label-for="input-1">
					<label>Filter Name
						<sup class="text-danger font-weight-boldest">*</sup></label>
					<b-form-input v-model="form.name" type="text" name="name" class="form-control"
						placeholder="EG: Shape">
					</b-form-input>
				</b-form-group>
				<b-row>
					<b-col md="4">
						<b-form-group label-for="input-1">
							<label>Filter Group
								<sup class="text-danger font-weight-boldest">*</sup></label>
		 					<b-form-select v-model="form.filter_group_id"  name="group" :options="groups"></b-form-select>
						</b-form-group>
					</b-col>
				</b-row>
				<b-form-group label-for="input-1">
					<label>Sort Order
						<sup class="text-danger font-weight-boldest">*</sup></label>
					<b-form-input v-model="form.sort_order" type="number" name="sort" class="form-control"></b-form-input>
				</b-form-group>
				<b-row> </b-row>
				<b-row>
					<b-col class="text-right">
						<button ref="kt_login_signin_submit" class="btn btn-sm btn-outline-primary font-size-h6">
							<i class="las la-save"></i> Save
						</button>
					</b-col>
				</b-row>
			</form>
		</b-card>
	</div>
</template>

<script>
	import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
	import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

	// FormValidation plugins
	import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
	import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
	import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
	import KTUtil from "@/assets/js/components/util";
	import Swal from "sweetalert2";
	import FilterService from "@/core/services/api/filter";
	export default {
		components: {},
		data() {
			return {
				title: "New Filter",
				id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
				currentImage:null,
            	previewImage:null,
				loading: false,
				imgPath:process.env.VUE_APP_API_STORAGE_URL,
				form: {
					id: null,
					name: "",
					sort_order: "",
					filter_group_id: 0,
					image: null,
				},
				groups:[],
				deletList:[],
			};
		},
		created() {
			this.getFilterGroups();
			if(this.id != null){
				this.getFilterById();
			}
		},
		mounted() {
			this.$store.dispatch(SET_BREADCRUMB, [
				{
					title: "Dashboard",
					route: "/dashboard",
				},
				{
					title: "Filter",
					route: "/filter",
				},
				{
					title: "Filter : New",
				},
			]);
			const signin_form = KTUtil.getById("product_basic");
			this.fv = formValidation(signin_form, {
				fields: {
					name: {
						validators: {
							notEmpty: {
								message: "Name is required",
							},
						},
					},
					group: {
						validators: {
							notEmpty: {
								message: "Filter group is required",
							},
						},
					},
				},
				plugins: {
					trigger: new Trigger(),
					submitButton: new SubmitButton(),
					bootstrap: new Bootstrap(),
				},
			});
			this.fv.on("core.form.valid", () => {
				// set spinner to submit button
				const submitButton = this.$refs["kt_login_signin_submit"];
				submitButton.classList.add("spinner", "spinner-light", "spinner-right","disabled");
				var vm = this;
				const formData = new FormData();
            	formData.append("name", vm.form.name);
            	formData.append("sort_order", vm.form.sort_order);
            	formData.append("group_id", vm.form.filter_group_id);
				if(vm.currentImage != null )
            		formData.append("image", vm.currentImage);
				if(this.id == null){
					this.createFilter(formData,function(response){
						vm.response(response,submitButton);
					});
				}else{
					this.updateFilter(formData,function(response){
						vm.response(response,submitButton);
					});
				}
			});
			
		},
		methods: {
			async getFilterGroups() {
				var response = await FilterService.getFilterGroups();
				this.groups.push ({
					value:0,
					text:"Please select a filter group"
				});
				response.data.map((e)=>{
					this.groups.push ({
						value:e.id,
						text:e.name
					})
				});
			},
			selectImage() {
				this.currentImage = this.$refs.file.files.item(0);
				this.previewImage = URL.createObjectURL(this.currentImage);
			},
			response(response,submitButton){
				var vm = this;
				if (response) {
					let timerInterval;
					Swal.fire({
						title: response.message,
						timer: 500,
						icon: "success",
						timerProgressBar: true,
						showConfirmButton: false,
						showCancelButton: false,
						didOpen: () => {
							Swal.showLoading();
							const b = Swal.getHtmlContainer().querySelector("b");
							timerInterval = setInterval(() => {
								b.textContent = Swal.getTimerLeft();
							}, 100);
						},
						willClose: () => {
							clearInterval(timerInterval);
						},
					}).then((result) => {
						vm.$router.push({ name: "filter" })
						/* Read more about handling dismissals below */
						if (result.dismiss === Swal.DismissReason.timer) {

						}
					});
				}
				submitButton.classList.remove(
					"spinner",
					"spinner-light",
					"spinner-right",
					"disabled"
				);
			},
			async getFilterById() {
				var response = await FilterService.getFilterById(this.id);
				this.form = response;
			},
			createFilter(formData,callback) {
				FilterService.createFilter(formData).then(function (response) {
					callback(response);
				});
			},
			updateFilter(formData,callback) {
				FilterService.updateFilter(formData,this.id).then(function (response) {
					callback(response);
				});
			},
		},
	};
</script>

<style></style>